@tailwind base;
@tailwind components;
@tailwind utilities;


@media (max-width: 640px) {
    .grid-cols-2 {
      grid-template-columns: 1fr;
    }
  }
  
/* .py-24{
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.search-result {
  background: #eaeaea;
  color: #000;
  border: 1px solid #fff;
  border-radius: 3px;
  margin-bottom: 10px;
}

.search-result-vehicle-container {
  display: flex;
  flex-direction: column;
}
.search-result-vehicle-container, .more-information-vehicle {
  width: 71.3%;
  float: left;
  padding: 20px 10px 10px 20px;
  background: #f2f2f2;
}


.search-result-vehicle, .more-information-vehicle-description {
  width: 70%;
  display: inline-block;
  float: left;
}
.vehicle-attributes {
    vertical-align: top;
    min-width: 83px;
    display: block;
    margin-top: 16px;
}

.vehicle-attributes .text {
  text-transform: uppercase;
  color: #000;
  font-family: 'woodford-main-bold', 'Arial', 'Helvetica', sans-serif;
  font-size: 10px;
  display: inline-block;
  vertical-align: middle;
  height: 25px;
  line-height: 25px;
}

.search-result-title {
  display: inline-block;
  width: 100%;
}


.search-result-title h2, .search-result-title .title {
    color: #177ed7;
    font-family: 'woodford-title', 'Arial', 'Helvetica', sans-serif;
    font-size: 24px;
    display: inline-block;
    width: 70%;
    padding: 0;
    margin: 0;
}



.search-result-title .codes {
    float: right;
    display: inline-block;
    width: 30%;
}

.search-result-title .category {
  color: #777;
  text-transform: uppercase;
  font-size: 14px;
  display: block;
  margin-top: 3px;
}

.search-result-details {
  flex-grow: 1;
}

.search-features-container {
  font-size: 11px;
}
.search-features-container, .more-information-exclusions {
  width: 30%;
  display: inline-block;
}

.search-button-row {
  display: inline-block;
  width: 100%;
  margin-top: 15px;
}

.search-button-row .left-side {
  display: inline-block;
}

.search-button-row .right-side {
  float: right;
  display: inline-block;
}

.more-information-button {
  max-width: 182px;
}
.more-information-button, .panel-button {
  background: #fff;
  color: #656565;
  display: block;
  border-right: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
  border-radius: 3px;
  padding: 3px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}

.more-information-button, .panel-button {
  background: #fff;
  color: #656565;
  display: block;
  border-right: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
  border-radius: 3px;
  padding: 3px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}


.more-information-costs, .search-result-price-container {
  width: 28%;
  display: inline-block;
  padding: 15px;
  box-sizing: border-box;
}

.search-result-price-container .select-rate {
  font-family: 'woodford-main-bold', 'Arial', 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 3px;
}

.search-result-price-container .rate-inclusions {
  margin-top: -3px;
  margin-bottom: 4px;
  margin-left: 14px;
}

.search-result-price-container .price {
  font-family: 'Arial', 'Helvetica', sans-serif;
  font-size: 30px;
  font-weight: 800;
  margin-top: 20px;
  text-align: right;
  display: inline-block;
  width: 100%;
  margin-bottom: -6px;
}

.search-result-price-container .days {
  text-align: right;
  color: #888;
  font-size: 12px;
}

.search-result-price-container .book-button {
  display: block;
  margin-top: 8px;
  font-size: 15px;
  padding: 8px 18px;
  border-radius: 4px;
}

.btn {
  background: #177ed7;
  color: #fff;
  border-radius: 5px;
  font-size: 11px;
  padding: 6px 18px;
  border: 0;
  font-family: 'woodford-main-bold', 'Arial', 'Helvetica', sans-serif;
  text-transform: uppercase;
}

.btn-success {
  color: #fff;
  background-color: #177ed7;
  border-color: #177ed7;
}

.btn {
  background: #177ed7;
  color: #fff;
  border-radius: 5px;
  font-size: 11px;
  padding: 6px 18px;
  border: 0;
  font-family: 'woodford-main-bold', 'Arial', 'Helvetica', sans-serif;
  text-transform: uppercase;
}

a {
  color: inherit;
  text-decoration: inherit;
} */